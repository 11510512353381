<template>
    <div class="create__calendar">
        <div class="header" v-if="!isForm">
            <div class="header__item" @click="close">
                <span class="iconify" data-icon="uil:multiply" :style="'color: red;'" data-width="22" data-height="22"></span>
            </div>
            <div class="header__item">
                Create New Calendar
            </div>
            <div class="header__item" @click="submit" :key="isReadyToCreate"
                v-bind:class="{disabled: !isReadyToCreate}">
                <span v-if="!isReadyToCreate" class="iconify" data-icon="uil:check" 
                    :style="'color: grey'" data-width="26" data-height="26"></span>
                <span v-else class="iconify" data-icon="uil:check" 
                    :style="'color: #0075ff'" data-width="26" data-height="26"></span>
            </div>
        </div>
        <div class="change__photo">
            <div class="photo__cont">
                <div class="photo__circle"></div>
            </div>
            <div class="change__title">Change photo</div>
        </div>
        <div class="fields__cont">

            <!-- Name -->
            <b-field 
                :type="form.uidError?
                    'is-danger' : ''"
                :message="form.uidError? 
                    form.uidError :
                    'Will be part of URL. Only a-z, 0-9 are allowed'">
                <b-input placeholder="Calendar name"
                    v-model="form.uid" type="text" required
                    validation-message="Only lowercase and digits is allowed"
                    pattern="[0-9a-z]*"
                    @input="() => form.uidError = null">
                </b-input>
            </b-field>

            <!-- Title -->
            <b-field
                :type="form.titleError?
                    'is-danger' : ''"
                :message="form.titleError? 
                    form.titleError : ''">
                <b-input placeholder="Calendar title"   
                    v-model="form.title" type="text" required
                    @input="() => form.titleError = null">
                </b-input>
            </b-field>

            <!-- Description -->
            <textarea class="textarea" placeholder="Calendar description"
                v-model="form.description">
            </textarea>


            <!-- <DropButton
            :form="form" name="country"
            :setter="updateCountryIndex"
            :current="form.countryIndex" :items="countries"
            placeholder="Country" 
            about="Calendar country"/> -->

            <!-- <b-field>
                <b-select placeholder="County" expanded
                    v-model="form.countryId" type="textarea" required>
                    <option
                        v-for="country in countries"
                        :value="country.id"
                        :key="country.name">
                        {{ country.name }}
                    </option>
                </b-select>
            </b-field> -->

            <b-field>
                    <b-autocomplete
                        v-model="searchCountry"
                        :data="search(countries, searchCountry)"
                        field="name"
                        placeholder="Country"
                        clearable
                        @blur="() => {
                            if (searchCountry.length == 0)
                                updateCountryId(form.countryId)
                        }"
                        @select="option => {
                            if (option)
                                updateCountryId(option.id)
                        }">
                        <template #empty>No results found</template>
                    </b-autocomplete>
            </b-field>

            <!-- <DropButton
            :form="form" name="timezone"
            :setter="updateTimezoneIndex"
            :current="form.timezoneIndex" :items="timezones"
            placeholder="Timezone" 
            about="Calendar timezone"/> -->

            <!-- <b-field
                :type="form.timezoneError?
                    'is-danger' : ''"
                :message="form.timezoneError? 
                    form.timezoneError : ''">
                <b-select placeholder="Timezone" expanded
                    v-model="form.timezoneId" required
                    @input="() => form.timezoneError = null">
                    <option
                        v-for="timezone in timezones"
                        :value="timezone.id"
                        :key="timezone.name">
                        {{ timezone.name }}
                    </option>
                </b-select>
            </b-field> -->
            <b-field
                :type="form.timezoneError?
                    'is-danger' : ''"
                :message="form.timezoneError? 
                    form.timezoneError : ''">
                    <b-autocomplete
                        v-model="searchTimezone"
                        :data="search(timezones, searchTimezone)"
                        field="name"
                        placeholder="Timezone"
                        clearable
                        @input="() => form.timezoneError = null"
                        @blur="() => {
                            if (searchTimezone.length == 0)
                                updateTimezoneId(form.timezoneId)
                        }"
                        @select="option => {
                            if (option)
                                updateTimezoneId(option.id)
                        }">
                        <template #empty>No results found</template>
                    </b-autocomplete>
            </b-field>



            <!-- <DropButton
            :form="form" name="holidaySet"
            :setter="updateHolidaySetIndex"
            :current="form.holidaySetIndex" :items="holidaySets"
            placeholder="Holiday set" 
            about="Calendar holiday set"/> -->

            <b-field
                :type="form.holidayError?
                    'is-danger' : ''"
                :message="form.holidayError? 
                    form.holidayError : ''">
                <b-select placeholder="Holiday set" expanded
                    v-model="form.holidaySet" required
                    @input="() => form.visibilityError = null">
                    <option
                        v-for="holidaySet in holidaySets"
                        :value="holidaySet.id"
                        :key="holidaySet.name">
                        {{ holidaySet.name }}
                    </option>
                </b-select>
            </b-field>

            <b-field
                :type="form.visibilityError?
                    'is-danger' : ''"
                :message="form.visibilityError? 
                    form.visibilityError : ''">
                <b-select placeholder="Visibility" expanded
                    v-model="form.visibility" required
                    @input="() => form.visibilityError = null">
                    <option
                        v-for="level in visibilityLevels"
                        :value="level.id"
                        :key="level.name">
                        {{ level.name }}
                    </option>
                </b-select>
            </b-field>

            <!-- <DropButton
            :form="form" name="visibility"
            :setter="(value)=>this.form.visibility=value"
            :current="form.visibility" :items="visibilityLevels"
            placeholder="Visibility level"
            about="Calendar visibility level"/> -->

            
            <div class="field">
                <div class="field__content">
                    <div class="fulltime">
                        <b-checkbox v-model="form.isFullDay"></b-checkbox>
                        24 working hours
                    </div>
                    <div class="not__fulltime">
                        <TimeInput
                        name="from" :form="form" :isDanger="form.timeError != null"
                        placeholder="07:00" v-model="form.from" :disabled="form.isFullDay"/>
                        <TimeInput 
                        name="to" :form="form" enableMidnight :isDanger="form.timeError != null"
                        placeholder="23:00" v-model="form.to"   :disabled="form.isFullDay" />                        
                    </div>
                </div>
                <div class="field__error" v-if="form && form.timeError">
                    {{form.timeError}}
                </div>
            </div>

            <SocialNetworkLinksSelector v-model="form.socialNetworkLinks"/>

        </div>
    </div>
</template>

<script>
import visibilityLevel from '../scripts/visibilityLevels.js'
import DropButton from '../components/inputs/DropButton.vue';
import AboutMessage from '../components/AboutMessage.vue';
import Field from '../components/inputs/Field.vue';
import CalendarCreationForm from '../components/forms/templates/create-calendar'
import router from '../router'; 
import CMS from '../service/cms/service';
import SocialNetworkLinksSelector from '../components/inputs/SocialNetworkLinksSelector.vue';
import TimeInput from '../components/inputs/TimeInput.vue';
import BaseTextarea from '../components/inputs/BaseTextarea.vue'
import BaseCheckbox from '../components/inputs/BaseCheckbox.vue';
import BaseInput from '../components/inputs/BaseInput.vue'
import CreateCalendarForm from '../components/forms/templates/create-calendar';

export default {
    name: "CreateCalendar",
    components:{
        DropButton,
        BaseTextarea,
        BaseCheckbox,
        BaseInput,
        AboutMessage,
        Field,
        TimeInput,
        SocialNetworkLinksSelector,
    },
    props:{
        isForm: {
            type: Boolean,
            default: false
        },
        calendarSettings: {
            type: Object
        },
        action: {
            type: Function
        }
    },
    computed: {
        userCountry(){
            let countryId = this.$store.getters.userCountryId
            return countryId != null ? countryId : null 
        },
        userTimezone(){
            let timezoneId = this.$store.getters.userTimezoneId
            return timezoneId != null ? timezoneId : null
        },
        countries(){
            let countries = this.$store.getters.countries
         
            let currentCountry = countries.find((country) => {
                return country.id == this.form.countryId
            })
            if (currentCountry)
                this.searchCountry = currentCountry.name
            return countries
        },
        timezones(){
            let timezones = this.$store.getters.timezones 
                     
            let currentTimezone = timezones.find((timezone) => {
                return timezone.id == this.form.timezoneId
            })
            if (currentTimezone)
                this.searchTimezone = currentTimezone.name
            return timezones
        },
        visibilityLevels(){
            return visibilityLevel
        }, 
        isReadyToCreate(){
            let disableErrorMessage = false
            return this.form.validate(disableErrorMessage)
        }
    },
    data() {
        return {
            form: new CalendarCreationForm(),
            fromTimeArchive: "",
            fromIsErrorArchive: false,
            toTimeArchive: "",
            toIsErrorArchive: false,
            holidaySets: [],
            searchTimezone: "",
            searchCountry: "",
            isFormSubmitted: false,
        }
    },
    watch: {
        calendarSettings(newValue) {
            if (newValue && this.isForm) {
                this.form = this.form.fill(this.calendarSettings)
                this.updateHolidaySet(this.form.countryId)
            }
        },
        userCountry(newValue) {
            if (!this.form.countryId && newValue) {
                this.updateCountryId(newValue)
            }
        },
        userTimezone(newValue) {
            if (!this.form.timezoneId && newValue) {
                this.updateTimezoneId(newValue)
            }
        },
        'form.from': {
            handler(newValue) {
                this.form.timeError = null
            },
            deep: true,
        },
        'form.to': {
            handler(newValue) {
                this.form.timeError = null
            },
            deep: true,
        },
        'form.isFullDay': {
            handler(newValue) {
                if (newValue == true) {
                    this.fromTimeArchive = this.form.from
                    this.toTimeArchive = this.form.to

                    this.fromIsErrorArchive = this.form.fields["from"].isError
                    this.form.fields["from"].isError = false

                    this.toIsErrorArchive = this.form.fields["to"].isError
                    this.form.fields["to"].isError = false

                    this.form.from = "00:00"
                    this.form.to = "24:00"
                } 
                else {
                    this.form.from = this.fromTimeArchive
                    this.form.to = this.toTimeArchive
                    if (this.form.fields["from"])
                        this.form.fields["from"].isError = this.fromIsErrorArchive
                    if (this.form.fields["to"])
                        this.form.fields["to"].isError = this.toIsErrorArchive
                }
            },
            deep: true,
        }
    },
    created(){  
        // Add fields to form
        if (this.isForm && this.calendarSettings) {
            let calendarSettingsIsNULL = this.calendarSettings.uid == ""
            if (calendarSettingsIsNULL) return
        

            this.form = this.form.fill(this.calendarSettings)
            this.updateHolidaySet(this.form.countryId)
        }


        if (!this.isForm && !this.calendarSettings) {
            if (!this.form.countryId && this.userCountry != -1) {
                this.updateCountryId(this.userCountry)
            }
            if (!this.form.timezoneId && this.userTimezone != -1) {
                this.updateTimezoneId(this.userTimezone)
            }
        } 
        // TODO : Setup validation of fields        
    },
    methods: {
        close(){
            router.back()
        },
        submit(){
            let isValid = this.form.validate()
            if (!isValid || this.isFormSubmitted) 
                return

            this.isFormSubmitted = true
            let newCalendarData = this.form.toSubmitData()
            CMS.calendar.create(newCalendarData).then( newCalendar => {
                if (newCalendar.err){
                    throw newCalendar.err
                }
                
                this.form = new CreateCalendarForm()
                let uid = newCalendar.uid
                CMS.calendar.getByUid(uid).then( calendarData => {
                    if (calendarData.err){
                        throw calendarData.err
                    }

                    // Add calendar data to future view (settings or slot table)
                    this.$store.dispatch('set-last-calendar', calendarData)
                    
                    // Add calendar data to user managed calendars (for personal calendar list)
                    this.$store.dispatch('add-managed-calendar', {uid, calendar: calendarData})
                    
                    this.$store.dispatch("set-calendar", {
                        uid: uid,
                        calendar: calendarData,
                    })
                    this.$store.dispatch("setup-slot-templates")
					this.setupWorkdayCalendar(calendarData.workdayCalendarId)

                    // Setup work hours for the calendar
                    this.setupWorkHours({
                        start: calendarData.from,
                        end: calendarData.to,
                    })
                    
                    router.push({
                        name: "calendar-settings-menu", 
                        params: {
                            calendarUid: uid, 
                            isNewCalendar: true
                        }
                    })
                })
            }).catch(err => {
                if (err.response.status == 409) {
                    this.form.calendarWithThisUidExist()
                }
            })
            .finally(() => {
                this.isFormSubmitted = false
            })
        },

        nameValidator(name){
            return name.toLowerCase().replace(/[^a-z0-9-_]/g, "")
        },  

        updateCountryId(newValue){
            this.form.countryId = newValue
            this.form.countryIndex = newValue - 1
            if (!newValue) return 

            let currentCountry = this.countries.find((country) => {
                return country.id == newValue
            })
            if (currentCountry)
                this.searchCountry = currentCountry.name
            this.updateHolidaySet(this.form.countryId)
        },
        updateCountryIndex(newValue){

            if (!newValue) {
                this.form.countryIndex = null
                this.form.countryId = null
                return
            }

            this.form.countryIndex = newValue
            this.form.countryId = newValue + 1

            if (!newValue) return 

            this.updateHolidaySet(this.form.countryId)
        },

        updateTimezoneId(newValue) {
            this.form.timezoneId = newValue
            this.form.timezoneIndex = newValue - 1
            let currentTimezone = this.timezones.find((timezone) => {
                return timezone.id == newValue
            })
            if (currentTimezone)
                this.searchTimezone = currentTimezone.name
        },

        updateTimezoneIndex(newValue) {
            if (!newValue) {
                this.form.timezoneIndex = null
                this.form.timezoneId = null
                return
            }

            this.form.timezoneIndex = newValue
            this.form.timezoneId = newValue + 1
        },

        updateHolidaySetIndex(newValue) {
            this.holidaySetIndex = newValue
            this.holidaySet = newValue != null ?
                this.holidaySets[newValue].id
                :null
        },
        // updateHolidaySet(newValue) {
        //     this.form.
        // }

        updateHolidaySet(countryId){
            return CMS.geo.workdayCalendar.getByCountry(countryId).then(holidaySets => {
                if (holidaySets.err){
                    throw holidaySets.err
                }

                this.holidaySets = holidaySets ?? []
                if (holidaySets && holidaySets.length > 0){
                    let holidaySet = holidaySets[0]

                    this.form.holidaySet = holidaySet.id
                    this.form.holidaySetIndex = 0
                }
            })
        },
        search(items, searchString){
            if (!searchString || searchString.length == 0)
                return items
            
            let search = searchString.toLowerCase()

            return items.filter(item => {
                if (item.title)
                    return item.title.toLowerCase().includes(search)
                else if (item.name)
                    return item.name.toLowerCase().includes(search)
                else if (item.Name)
                    return item.Name.toLowerCase().includes(search)
            }
            )
        },
        setupWorkdayCalendar(workdayCalendarId){
			CMS.geo.workdayCalendar
				.getByID(workdayCalendarId)
				.then((workdayCalendar) => {
					if (workdayCalendar.err) 
                        throw workdayCalendar.err

					let holidays = this.setupHolidays(workdayCalendar.weekends)
					let special = this.setupSpecialDays(workdayCalendar.specialDays)
					this.$store.dispatch("update-work-date", {
						holidays: holidays,
						special: special,
					});
				})
		},
		setupWorkHours(work){
            let start = {
                hour: Math.floor(work.start / 60),
                minute: work.start - Math.floor(work.start / 60) * 60
            }
            let end = {
                hour: Math.floor(work.end / 60),
                minute: work.end  - Math.floor(work.end / 60) * 60
            }

            this.$store.dispatch('update-work-time', {start, end})
        },

        setupSpecialDays(specialDays) {
            let special = specialDays || [];
            return special.map((day) => {
                if (day["dt"] !== undefined) {
                    let date = new Date(day["dt"]);
                    return {
                        m: date.getMonth(),
                        d: date.getDate(),
                        typeId: day.typeId,
                    };
                }

                return { ...day, m: day.m - 1 };
            });
        },

        setupHolidays(holidays) {
            return holidays.map((dayIndex) => {
                if (dayIndex == 7) return 0;
                return dayIndex;
            });
        },
    },
}
</script>

<style scoped> 
.description{
    min-height: 4em;
}
.textarea:not([rows]){
    min-height: 5em;
    margin-bottom: 0.75rem;
    max-height: 9em;
}
.create__calendar{
    display: flex;
    flex-direction: column;
    animation: fade .3s;
    align-items: center;
}

.header{
    font-size: 18px;
    display: grid;
    font-weight: 600;
    padding: 5px;
    background: lightgrey;
    position: fixed;
    width: 100%;
    z-index: 2;
    grid-template-columns: 27px calc(100% - 54px - 5px) 27px;

    position: fixed;
    top: 0;
}
.header__item{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: background .3s;
}

.header__item.disabled{
    background: #c6c6c6;
}

.change__photo{
    padding-top:60px
}

.photo__cont{
    display: flex;
    justify-content: center;
}
.photo__circle{
    height: 120px;
    width: 120px;

    border: 1px solid black;
    border-radius: 75px;
}

.change__title{
    display: flex;
    margin-top: 10px;
    text-decoration: underline;
    justify-content: center;
    color:#0075ff;
}



.fields__cont{
    padding: 16px 10px;
    min-width: 320px;
    width: calc(100% - 20px);

    display: flex;
    flex-direction: column;
}


input.disabled{
    background: #e9e9e9;
    color: grey;
}

.checkbox{
    width: 20px;
    height: 20px;
}

.fulltime{
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
}

.fulltime > * {
    display: flex;
    align-items: center;
}

.not__fulltime{
    display: flex;
    gap: 10px;
}

.not__fulltime > input{
    width: 41px;
}

.field__content{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    height: 40px;
}

.field__error{
    color: red;
    font-size: 11px;
}

.right {
    text-align: end;
}

</style>