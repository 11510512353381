import Form from "../../../scripts/form"
import {HHMMToMinutes, isValidHHMM,  minutesToHHMM} from "../../../scripts/date"

let SOCIAL_NETWORK_LINKS = {
	"fb"  : {type: "facebook", 		icon: "uil:facebook-f", 		},
	"twt" : {type: "twitter",  		icon: "uil:twitter-alt", 		},
	"ins" : {type: "instagram",		icon: "uil:instagram", 			},
	"loc" : {type: "location", 		icon: "uil:map-marker", 		},
	"web" : {type: "site", 			icon: "carbon:earth-filled", 	},
	"ema" : {type: "email",			icon: "uil:home", 				},
}


class CreateCalendarForm extends Form{
	uid = ""
	uidError = null
	title = ""
	titleError = null
	description = ""

	countryId  = null
	countryError = null

	timezoneId = null
	timezoneError = null
	
	holidaySet = null
	holidayError = null
	
	visibility = null
	visibilityError = null

	countryIndex  	= null
	timezoneIndex 	= null
	holidaySetIndex = null

	from		= "07:00"
	to   		= "23:59"
	timeError	= null
	isFullDay 	= false

	socialNetworkLinks = []
	
	validate(isSetError=true){
		// Uid
		let uidIsClear = this.uid.length == 0
		let uidIsValid = this.validateStringByRegex(this.uid, /[^a-z0-9-_]/g) 
		if (uidIsClear) {
			if (isSetError)
				this.uidError = "Calendar name is not entered"
			return false
		}
		if (!uidIsValid){
			if (isSetError)
				this.uidError = "Calendar name is not valid"
			return false
		}

		// Title
		let titleIsClear = this.title.length == 0
		if (titleIsClear) {
			if (isSetError)
				this.titleError = "Calendar title is not entered"
			return false
		}


		// Timezone
		let timezoneIsClear = this.timezoneId == null
		if (timezoneIsClear) {
			if (isSetError)
				this.timezoneError = "Calendar timezone is not selected"
			return false
		}

		// Holiday set
		let holidaySetIsClear = this.holidaySet == null
		if (holidaySetIsClear) {
			if (isSetError)
				this.holidayError = "Calendar holiday set is not selected"
			return false
		}

		// Visibility
		let visibilityIsClear = this.visibility == null
		if (visibilityIsClear) {
			if (isSetError)
				this.visibilityError = "Calendar visibility is not selected"
			return false
		}

		// Worktime
		if (!this.isFullDay) {
			
			let fromIsClear = this.from.length == 0
			let fromIsValid = isValidHHMM(this.from)
			if (fromIsClear || !fromIsValid) {
				if (isSetError)
					this.timeError = "Please enter the start time in the format 'HH:MM'"
				return false
			}
			
			let toIsClear = this.to.length == 0
			let toIsValid = isValidHHMM(this.to)
			if (toIsClear || !toIsValid) {
				if (isSetError)
					this.timeError = "Please enter the end time in the format 'HH:MM'"
				return false
			}

		}
		
		return true
	}

	validateStringByRegex(str, regexp) {
		return !regexp.test(str)
	}

	toSubmitData(){
		// TimezoneID        int                        `json:"timezoneId"`
		// WorkdayCalendarID int                        `json:"workdayCalendarId"`
		// VisibilityLevelID bl.CalendarVisibilityLevel `json:"visibilityLevelId"`
		// UID         string            `json:"uid"`
		// Title       string            `json:"title"`
		// Description string            `json:"description,omitempty"`
		// SocialLinks map[string]string `json:"socialLinks,omitempty"`
		// ??? Lat         *float64          `json:"lat,omitempty"`
		// ??? Lon         *float64          `json:"lon,omitempty"`
		// Address     string            `json:"address,omitempty"`
		// FromHHMM    int               `json:"from"`
		// ToHHMM      int               `json:"to"`


		// Filter empty fields and convert to object 
		let socialLinks = this.socialNetworkLinks
			.filter(link => link.value.length != 0 && link.json)
			.reduce((data, link) => ({ ...data, [link.json]: link.value}), {}) 

		let socialLinksCount = Object.keys(socialLinks).length

		let submitData = {
			uid: this.uid, 
			title: this.title,
			description: this.description? this.description : undefined,

			countryId: this.countryId,
			timezoneId: this.timezoneId,
			workdayCalendarId: this.holidaySet,
			visibilityLevelId: this.visibility,

			from: this.isFullDay?0:HHMMToMinutes(this.from),
			to:   this.isFullDay?1440:HHMMToMinutes(this.to),

			socialLinks: socialLinksCount > 0?
				socialLinks : undefined,
		}

		return submitData
	}

	calendarWithThisUidExist(){	
		this.uidError = "This calendar name is busy"
	}

	fill(calendarData) {
		let newForm = new CreateCalendarForm()
		newForm.uid	 = calendarData.uid
		newForm.title = calendarData.title
		newForm.description = calendarData.description ?? ""

		newForm.countryId  = calendarData.countryId
		newForm.countryIndex = calendarData.countryId - 1
		
		newForm.timezoneId = calendarData.timezoneId
		newForm.timezoneIndex = calendarData.timezoneId - 1

		newForm.holidaySet = calendarData.workdayCalendarId
		newForm.holidaySetIndex = 0

		newForm.visibility = calendarData.visibilityLevelId - 1

		newForm.from		= minutesToHHMM(calendarData.from)
		newForm.to   		= minutesToHHMM(calendarData.to)
		newForm.isFullDay 	= false

		if (calendarData.socialLinks) {
			newForm.socialNetworkLinks = Object.keys(calendarData.socialLinks).map(linkJson => {
				return {
					json: linkJson,
					value: calendarData.socialLinks[linkJson],
					icon: SOCIAL_NETWORK_LINKS[linkJson].icon,
					type: SOCIAL_NETWORK_LINKS[linkJson].type,
				}
			})
		}		

		newForm.fields = this.fields

		return newForm
	}
}

export default CreateCalendarForm