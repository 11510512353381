<template>
	<div class="base-input">
		<div class="base-input__field">
			<div class="field__input" v-bind:class="{
				error: field.isError,
				focus: isFocused
			}">
				<input
				ref="base-input" 
				:type="inputType" 
				v-model="currentValue" 
				@input="inputHandler"
				@focus="isFocused = true"
				@blur="isFocused = false"
				:max="limits.max"
				:min="limits.min"
				:maxlength="limits.maxlength"
				:inputmode="inputType=='number'?'numeric':'text'"
				:placeholder="placeholder">
				<div class="field__buttons" v-if="inputType == 'number' && showNumberButtons">
					<div class="field__button" @click="plusHandler">
						<span class="iconify" data-icon="uil:angle-up"></span>
					</div>
					<div class="field__button" @click="minusHandler">
						<span class="iconify" data-icon="uil:angle-down"></span>
					</div>
				</div>
			</div>
			<div class="field-about" v-if="about">
				<AboutMessage :message="about"/>
			</div>
		</div>
		<div class="base-input__annotation" v-if="annotation">
			{{annotation}}
		</div>
		<div class="base-input__error" v-if="field.isError && showErrorMessage">
			{{field.error()}}
		</div>
	</div>
</template>

<script>
import AboutMessage from '../AboutMessage.vue';
import Form from "../../scripts/form.js"
import Field from "../../scripts/field.js"


export default {
	name: 'BaseInput',
	components: {
		AboutMessage,
	},
	model: {
		prop: 'value',
		event: 'input'
	},
	props: {
		value: {
			type: [String, Number],
			required: true,
		},
		placeholder: {
			type: String,
		},
		annotation: {
			type: String,
		},
		about: {
			type: String,
		},
		name: {
			type: String,
		},
		form: {
			type: Form,
		},
		validator: {
			type: Function,
		},
		inputType: {
			type: String,
			default: "text",
		},
		showNumberButtons: {
			type: Boolean,
			default: false,
		},
		autoFocus: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
			default: true,
		},
		limits: {
			type: Object,
			default: () => {
				return {
					max: undefined,
					min: undefined,
					maxlength: undefined,
				}
			}
		}
	},

	watch: {
		value(newValue) {
			this.currentValue = newValue
		}
	},

	created(){
		if (this.form){
			this.form.addField(this.field)
		}
	},

	mounted() {
		if (this.autoFocus){
			this.$refs["base-input"].focus()
		}
	},

	data() {
		return {
			field: new Field(this.name ?? "", "Field value is not valid"),
			currentValue: this.value,
			isFocused: false,
		};
	},

	methods: {
		inputHandler(ev){
			if (this.field.isError) {
				this.field.isError = false
			}

			if (this.validator) {
				let fixed = this.validator(this.currentValue)
				if (fixed != this.currentValue) {
					this.field.isError = true
				}
			}

			if (this.inputType == "number" && this.currentValue != ""){
				this.currentValue = Number(this.currentValue)
			}

			this.$emit('input', this.currentValue)
		},
		plusHandler(){
			if (this.currentValue == "") this.currentValue = 0
			this.currentValue += 1
			this.$emit('input', this.currentValue)
		},
		minusHandler(){
			this.currentValue -= 1
			this.inputHandler()
			if (this.currentValue == "") this.currentValue = 0
			this.$emit('input', this.currentValue)
		}
	},
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.base-input{
	width: 100%;
}
.base-input__field{
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.field__input{
	width: 100%;
	border: 1px solid;
    background-color: white;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;

	display: flex;
	flex-direction: row;
}

.field__input.focus{
    border-color: hwb(212 0% 0%);
    box-shadow: 0px 0 0 2px #cbe3ff;
}

.field__input.error{
    border-color: 	#ff3333;
    box-shadow: 0px 0 0 2px #ffcece;
}

.field__input.error > input {
	color: red;
}

input{
    width: 100%;
    font-size: 16px; 
    padding: 7px 11px;
    height: 38px;
    border: none;
    border-radius: 4px;
}

input:focus{
    outline: none;
}

.base-input__annotation{
	font-size: 11px;
	color: grey;
}

.base-input__error{
	font-size: 11px;
	color: red;
}

.field__buttons{
	border-left: 1px solid rgb(68, 68, 68);
}

.field__buttons > *{
	height: 16px;
	width: 21px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #e7e7e7;
}

.field__buttons > *:active{
	background: #cdcdcd;
}

.field__button + .field__button{
	border-top: 1px solid rgb(68, 68, 68);
}

</style>